// Default Colors
$blue: $color-primary;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: $color-error;
$orange: #fd7e14;
$yellow: $color-warn;
$green: $color-success;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$black: #000;

/* Default Grays */
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// Equivalents to bg-light/dark as well as default for gray
$light: $gray-100;
$really-dark: #23282d;
$dark: $gray-800;
$gray: $gray-600;

// Default colors
$primary: $blue;
$secondary: $gray-100;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;

// Resume Colors
$mason: #063;
$mason-gold: #fc3;
$ecpi: #104ba0;
$publix: #3e8c2f;
$trolley: #6b1a23;
$longevity-blue: #002754;

// Array of theme colors that can be added to
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "black": $black,
  "gray-dark": $color-gray-dark,
  "really-dark": $really-dark,
  "mason": $mason,
  "ecpi": $ecpi,
  "publix": $publix,
  "trolley": $trolley,
  "longevity-blue": $longevity-blue
);

// Array of random colors
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray100": $gray-100,
  "gray200": $gray-200,
  "gray300": $gray-300,
  "gray400": $gray-400,
  "gray500": $gray-500,
  "gray600": $gray-600,
  "gray700": $gray-700,
  "gray800": $gray-800,
  "gray900": $gray-900
);

$font-size-base: 1rem;
$rfs-base-value: 1rem;
$fa-font-size-base: 1rem;
