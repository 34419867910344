// Replaces colors utilized by CMS Design
// Utilities Sass
//@import '../node_modules/@cmsgov/design-system/dist/scss/utilities/background-color';
//@import '../node_modules/@cmsgov/design-system/dist/scss/utilities/text-color';

.ds-base {
  .ds-u-color--base-flip:not(:focus) {
    color: $color-base !important;
  }

  .ds-u-fill--base-flip {
    background-color: $color-base !important;
  }

  .ds-u-fill--white-flip {
    background-color: $color-white !important;
  }
}

.ds-base--inverse {
  .ds-u-color--base-flip:not(:focus) {
    color: $color-base-inverse !important;
  }

  .ds-u-fill--base-flip {
    background-color: $color-background-inverse !important;
  }

  .ds-u-fill--white-flip {
    background-color: $color-black !important;
  }
}

// primary
.ds-u-fill--primary {
  background-color: $color-primary !important;
}

.ds-u-fill--primary-darker {
  background-color: $color-primary-darker !important;
}

.ds-u-fill--primary-darkest {
  background-color: $color-primary-darkest !important;
}

// primary-alt
.ds-u-fill--primary-alt {
  background-color: $color-primary-alt !important;
}

.ds-u-fill--primary-alt-dark {
  background-color: $color-primary-alt-dark !important;
}

.ds-u-fill--primary-alt-darkest {
  background-color: $color-primary-alt-darkest !important;
}

.ds-u-fill--primary-alt-light {
  background-color: $color-primary-alt-light !important;
}

.ds-u-fill--primary-alt-lightest {
  background-color: $color-primary-alt-lightest !important;
}

// gray
.ds-u-fill--gray {
  background-color: $color-gray !important;
}

.ds-u-fill--gray-dark {
  background-color: $color-gray-dark !important;
}

.ds-u-fill--gray-light {
  background-color: $color-gray-light !important;
}

.ds-u-fill--gray-lighter {
  background-color: $color-gray-lighter !important;
}

.ds-u-fill--gray-lightest {
  background-color: $color-gray-lightest !important;
}

.ds-u-fill--muted-inverse {
  background-color: $color-muted-inverse !important;
}

// Gold
.ds-u-fill--gold {
  background-color: $color-gold !important;
}

.ds-u-fill--gold-light {
  background-color: $color-gold-light !important;
}

.ds-u-fill--gold-lighter {
  background-color: $color-gold-lighter !important;
}

.ds-u-fill--gold-lightest {
  background-color: $color-gold-lightest !important;
}

.ds-u-fill--gold-dark {
  background-color: $color-gold-dark !important;
}

.ds-u-fill--gold-darker {
  background-color: $color-gold-darker !important;
}

.ds-u-fill--gold-darkest {
  background-color: $color-gold-darkest !important;
}

// Green
.ds-u-fill--green {
  background-color: $color-green !important;
}

.ds-u-fill--green-light {
  background-color: $color-green-light !important;
}

.ds-u-fill--green-lighter {
  background-color: $color-green-lighter !important;
}

.ds-u-fill--green-lightest {
  background-color: $color-green-lightest !important;
}

.ds-u-fill--green-dark {
  background-color: $color-green-dark !important;
}

.ds-u-fill--green-darker {
  background-color: $color-green-darker !important;
}

.ds-u-fill--green-darkest {
  background-color: $color-green-darkest !important;
}

// red
.ds-u-fill--red {
  background-color: $color-red !important;
}

.ds-u-fill--red-light {
  background-color: $color-red-light !important;
}

.ds-u-fill--red-lighter {
  background-color: $color-red-lighter !important;
}

.ds-u-fill--red-lightest {
  background-color: $color-red-lightest !important;
}

.ds-u-fill--red-dark {
  background-color: $color-red-dark !important;
}

.ds-u-fill--red-darker {
  background-color: $color-red-darker !important;
}

.ds-u-fill--red-darkest {
  background-color: $color-red-darkest !important;
}

// State color - error

.ds-u-fill--error {
  background-color: $color-error !important;
}

.ds-u-fill--error-light {
  background-color: $color-error-light !important;
}

.ds-u-fill--error-lighter {
  background-color: $color-error-lighter !important;
}

.ds-u-fill--error-lightest {
  background-color: $color-error-lightest !important;
}

.ds-u-fill--error-dark {
  background-color: $color-error-dark !important;
}

.ds-u-fill--error-darker {
  background-color: $color-error-darker !important;
}

.ds-u-fill--error-darkest {
  background-color: $color-error-darkest !important;
}

// State color - warn

.ds-u-fill--warn {
  background-color: $color-warn !important;
}

.ds-u-fill--warn-light {
  background-color: $color-warn-light !important;
}

.ds-u-fill--warn-lighter {
  background-color: $color-warn-lighter !important;
}

.ds-u-fill--warn-lightest {
  background-color: $color-warn-lightest !important;
}

.ds-u-fill--warn-dark {
  background-color: $color-warn-dark !important;
}

.ds-u-fill--warn-darker {
  background-color: $color-warn-darker !important;
}

.ds-u-fill--warn-darkest {
  background-color: $color-warn-darkest !important;
}

// State color - success

.ds-u-fill--success {
  background-color: $color-success !important;
}

.ds-u-fill--success-light {
  background-color: $color-success-light !important;
}

.ds-u-fill--success-lighter {
  background-color: $color-success-lighter !important;
}

.ds-u-fill--success-lightest {
  background-color: $color-success-lightest !important;
}

.ds-u-fill--success-dark {
  background-color: $color-success-dark !important;
}

.ds-u-fill--success-darker {
  background-color: $color-success-darker !important;
}

.ds-u-fill--success-darkest {
  background-color: $color-success-darkest !important;
}

// base colors

.ds-u-fill--black {
  background-color: $color-black !important;
}

.ds-u-fill--background {
  background-color: $color-background !important;
}

.ds-u-fill--background-inverse {
  background-color: $color-background-inverse !important;
}

.ds-u-fill--transparent {
  background-color: transparent !important;
}

// Focus colors
.ds-u-fill--focus-color-light {
  background-color: $color-focus-light !important;
}

.ds-u-fill--focus-color-dark {
  background-color: $color-focus-dark !important;
}

// Deprecated Focus colors
.ds-u-fill--focus-color {
  background-color: $color-focus !important;
}

.ds-u-fill--focus-color-inverse {
  background-color: $color-focus-inverse !important;
}

.ds-u-fill--focus-border-inverse {
  background-color: $color-focus-border-inverse !important;
}

// primary
.ds-u-color--primary:not(:focus) {
  color: $color-primary !important;
}

.ds-u-color--primary-darker:not(:focus) {
  color: $color-primary-darker !important;
}

.ds-u-color--primary-darkest:not(:focus) {
  color: $color-primary-darkest !important;
}

// primary alt
.ds-u-color--primary-alt:not(:focus) {
  color: $color-primary-alt !important;
}

.ds-u-color--primary-alt-light:not(:focus) {
  color: $color-primary-alt-light !important;
}

.ds-u-color--primary-alt-lightest:not(:focus) {
  color: $color-primary-alt-lightest !important;
}

.ds-u-color--primary-alt-dark:not(:focus) {
  color: $color-primary-alt-dark !important;
}

.ds-u-color--primary-alt-darkest:not(:focus) {
  color: $color-primary-alt-darkest !important;
}

// gray
.ds-u-color--gray:not(:focus) {
  color: $color-gray !important;
}

.ds-u-color--gray-lightest:not(:focus) {
  color: $color-gray-lightest !important;
}

.ds-u-color--gray-lighter:not(:focus) {
  color: $color-gray-lighter !important;
}

.ds-u-color--gray-light:not(:focus) {
  color: $color-gray-light !important;
}

.ds-u-color--muted:not(:focus) {
  color: $color-muted !important;
}

// states
.ds-u-color--error:not(:focus) {
  color: $color-error !important;
}

.ds-u-color--error-dark:not(:focus) {
  color: $color-error-dark !important;
}

.ds-u-color--error-darkest:not(:focus) {
  color: $color-error-darkest !important;
}

.ds-u-color--error-light:not(:focus) {
  color: $color-error-light !important;
}

.ds-u-color--success:not(:focus) {
  color: $color-success !important;
}

.ds-u-color--base-inverse:not(:focus) {
  color: $color-base-inverse !important;
}

.ds-u-color--muted-inverse:not(:focus) {
  color: $color-muted-inverse !important;
}

.ds-u-color--black:not(:focus) {
  color: $color-black !important;
}

.ds-u-color--white:not(:focus) {
  color: $color-white !important;
}

.ds-u-color--inherit:not(:focus) {
  color: inherit !important;
}
