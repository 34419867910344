/*!
 * Global Stylesheet
 * Designed by Tyler Wrenn, B.S. Information Technology
 */

// Start of design CMS fixes
$image-path: '../../images';

@import '../node_modules/@cmsgov/design-system/dist/scss/settings/variables/core-theme';

@import "vars";

// Old BS5 stuff
$navbar-brand-padding-y: ((1rem * 1.5 + 0.5rem * 2) - ((1rem * 1.25) * 1.5)) * 0.5;

@import "../node_modules/bootstrap/scss/bootstrap";
@import url('//fonts.googleapis.com/css2?family=Carter+One&family=Permanent+Marker&display=swap');
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid";

$color-background-inverse: $gray-900;

@import "utils";


%portfolio-card {
  @extend .card;

  justify-content: center !important;
  padding: 3rem !important;
  text-align: center !important;
  height: calc(100% - 15px);
}

.ds-base {
  --bs-body-bg: #{$color-gray-lighter};
  --bs-body-color: #{$color-base};
  --bs-gray-dark: #{$color-gray-light};
  --bs-gray-dark-rgb: #{to-rgb($color-gray-light)};

  .border-footer {
    border: 0;
    height: 1px;
    background-image: linear-gradient(90deg, transparent 0%, $black 50%, transparent 100%);
  }

  .ds-u-fill--gray-dark {
    background-color: $color-gray-light !important;
  }

  .portfolio-card {
    @extend %portfolio-card;

    box-shadow: 0 .125rem .25rem rgba($black, .075) !important;
    background-color: $color-gray-lightest !important;
  }

  .jumbotron-constrain {
    box-shadow: inset 0 500px 0 rgba($white, 0.3);
  }

  .nav-buttons {
    background-color: $color-background-inverse !important;
    border: 2px solid #495057;
    color: $white !important;

    &:hover {
      background-color: $white !important;
      border-color: $black;
      color: $black !important;
      text-decoration: none;
    }

    &.active {
      background-color: $color-background-inverse !important;
      border-color: #495057 !important;

      &:hover {
        color: $white !important;
        cursor: default !important;
        text-decoration: none !important;
      }
    }
  }

  .resume-badge {
    @extend .bg-dark;
    @extend .text-light;
  }
}

.ds-base--inverse {
  --bs-body-bg: #{$color-background-inverse};
  --bs-body-color: #{$color-base-inverse};

  .border-footer {
    border: 0;
    height: 1px;
    background-image: linear-gradient(90deg, transparent 0%, $white 50%, transparent 100%);
  }

  .portfolio-card {
    @extend %portfolio-card;
    
    color: $color-base !important;
    box-shadow: 0 .125rem .25rem rgba($black, .075) !important;
    background-color: $color-gray-lightest !important;
  }

  .jumbotron-constrain {
    box-shadow: inset 0 500px 0 rgba($black, 0.3);
  }

  .nav-buttons {
    background-color: $color-white !important;
    border: 2px solid $black;
    color: $black !important;

    &:hover {
      background-color: $gray-800 !important;
      border-color: $gray-800;
      color: $gray-100 !important;
      text-decoration: none;
    }

    &.active {
      background-color: $light !important;
      border-color: $black !important;

      &:hover {
        color: $gray-900 !important;
        cursor: default !important;
        text-decoration: none !important;
      }
    }
  }

  .resume-badge {
    @extend .bg-secondary;
    @extend .text-dark;
  }
}

// Add gray-dark text color as it wasn't there
.ds-u-color--gray-dark:not(:focus) {
  color: $color-gray-dark !important;
}

html {
  scrollbar-color: $light $dark;

  &::-webkit-scrollbar {
    width: 0.875rem;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, $dark, $really-dark);
    border-radius: 1.875rem;
    box-shadow: inset 0.125rem 0.125rem 0.125rem rgba($white, 0.25), inset -0.125rem -0.125rem 0.125rem rgba($black, 0.25);
  }

  &::-webkit-scrollbar-track {
    background: linear-gradient(90deg, $light, $light 0.0625rem, $gray-300 0, $gray-300);
  }
}

// Disable nasty v5 link underline
a {
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

// Adds font weight back to small class on migration
.small, small {
  font-weight: 400;
  // Add font size back so that it's dynamic on font size
  font-size: 80%;
}

.site-title {
  color: $white;
  font-family: 'Carter One', cursive;
  font-size: 4.5rem;
  margin: 0;
  padding: 0 0 -0.625rem;
  text-align: center;
  -webkit-text-stroke: 0.1875rem $black;
}

.countdown-text {
  color: $white;
  font-size: 2.8rem;
  font-weight: 900;
  text-align: center;
  -webkit-text-stroke: 0.1875rem $black;
}

.pm-font {
  font-family: 'Permanent Marker', sans-serif;
}

.front {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  margin: 0.625rem;
  text-align: center;

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    margin-right: -0.25rem;
    vertical-align: middle;
  }
}

@media (min-width: 576px) {
  .front {
    margin: 1.25rem;
  }
}

.jumbotron-inquire {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important;
}

.front-content {
  border-radius: 0.3rem;
}

.gay-bg {
  background: #f00;
  background: linear-gradient(to right, #f00 0%, #f0f 15%, #00f 33%, #0ff 49%, #0f0 67%, #ff0 84%, #f00 100%);
}

.jumbotron-constrain {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .feature-head {
    width: 80%;
  }
}

.thumbnail {
  background-color: $white;
  border: 1px solid $gray-300;
  border-radius: 0.25rem;
  display: block;
  height: 500px;
  line-height: 1.42857143;
  margin-top: 1.25rem;
  padding: 1.25rem 0.25rem 0.25rem;
  transition: border 0.2s ease-in-out;

  a > img,
  > img {
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .caption {
    color: $dark;
    padding: 0.5625rem;
  }

  .border-control {
    border: 5px solid $white;
    box-shadow: 0 0 0 3px $gray-400, 0 0 0 5px $gray-200;
  }
}

a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
  border-color: lighten($primary, 10%);
}

// Bootstrap Approach Featurettes

.marketing {
  .col-lg-4 {
    margin-bottom: 1.25rem;
    text-align: center;

    p {
      margin-left: 0.625rem;
      margin-right: 0.625rem;
    }
  }

  h2 {
    font-weight: normal;
  }
}

.featurette-divider {
  margin: 5rem 0;
}

.featurette-heading {
  font-weight: 300;
  letter-spacing: -1px;
  line-height: 1;
}

@media (min-width: 768px) {
  .featurette-heading {
    font-size: 3.125rem;
  }
}

@media (min-width: 992px) {
  .featurette-heading {
    margin-top: 7.5rem;
  }
}

.alert-static-top {
  border: 1px solid transparent;
  border-radius: 0 0 0.25rem 0.25rem;
  left: 0;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;
}

.alert-dismissible-static {
  padding-right: 4rem;

  .close {
    color: inherit;
    padding: 0.75rem 1.25rem;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.nav-item {
  @extend .px-1;
}

.fa-facebook-f {
  color: #3c5a99 !important;
}

.fa-twitter {
  color: #1da1f2 !important;
}

.fa-linkedin {
  color: #0077b5 !important;
}

.ng-touched.ng-invalid {
  @extend .is-invalid;
}

.ng-touched.ng-valid {
  @extend .is-valid;
}

.invalid-feedback {
  display: inherit !important;
}

.nav-buttons {
  @extend .btn;
  @extend .btn-light;
  @extend .rounded-0;
  @extend .btn-lg;
  //@extend .ds-c-button;

  border-radius: 0 !important;
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  padding: 0.5rem 1rem !important;
}

div#tsparticles,
ng-particles#tsparticles {
  background-position: 50% 50%;
  background-size: cover;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: -1;

  .tsparticles-canvas-el,
  canvas {
    animation: appear 1.4s 1;
    animation-fill-mode: forwards;
    opacity: 1;
    transform: scale(1);
  }
}

.jumbotron-constrain div#tsparticles,
.jumbotron-constrain ng-particles#tsparticles {
  height: unset !important;
  background-position: unset !important;

  canvas {
    height: auto !important;
  }
}

// Resume Badge CSS merge
.resume-badge {
  @extend .badge;
  @extend .rounded-pill;
  @extend .fw-normal;
}

// Particles Buttons Fix
#pauseResumeParticles,
#stopStartParticles {
  cursor: pointer;
}